import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const InfoPage = ({ data }) => {
    const posts = data.allReleaseNotes.nodes
    
    return (
        <Layout>
            <Seo title="Info" />
            <h1>Info</h1>
            <p>{JSON.stringify(posts)}</p>
            <Link to="/">Go back to the homepage</Link>
        </Layout>
    )
}

export default InfoPage

export const pageQuery = graphql`
  query {
    allReleaseNotes {
      nodes {
        id
        RowKey
        Body
        BugIDs
        EnhancementIDs
        Iteration
        Timestamp
      }
    }
  }  
`
